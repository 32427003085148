import { navigate } from "@/helpers/navigate";
import { GetHomepagePlaylistResponse } from "@/pages/api/playlists";
import { errorBoundary } from "@aiguestdj/shared/helpers/errorBoundary";
import { Box, Button } from "@mui/joy";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ConnectionContext } from "../ConnectionContext";
import PlaylistCard from "../Playlists/PlaylistCard";

type Props = {
}

export default function RecentPlaylists(props: Props) {

    const { plex, roon } = useContext(ConnectionContext);
    const [selectedPlaylistIdx, setSelectedPlaylistIdx] = useState<number>(0);
    const [playlists, setPlaylists] = useState<GetHomepagePlaylistResponse[]>([]);

    useEffect(() => {
        errorBoundary(async () => {
            const result = await axios.get<GetHomepagePlaylistResponse[]>(`/api/playlists?homepage=1`);
            setPlaylists(result.data);
        })
    }, [])

    const selectedPlaylist = playlists[selectedPlaylistIdx];
    const connected = plex || roon;
    return (<>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: 0.5, mx: 'auto' }} mb={1}>
            {[...Array(playlists.length)].map((_, index) => (
                <Box
                    key={index}
                    sx={{
                        cursor: "pointer",
                        padding: .5,
                        "& div": {
                            bgcolor: index === selectedPlaylistIdx ? 'primary.solidBg' : 'background.level3',
                            width: `max(${6 - index}px, 8px)`,
                            height: `max(${6 - index}px, 8px)`,
                            borderRadius: '50%'
                        },
                        "&:hover div": {
                            bgcolor: index === selectedPlaylistIdx ? 'primary.solidBg' : 'primary.50',
                        }
                    }}
                    onClick={() => setSelectedPlaylistIdx(index)}
                >
                    <Box />
                </Box>
            ))}
        </Box>

        {selectedPlaylist &&
            <PlaylistCard playlist={selectedPlaylist} footer={<>
                <Button component="a" href={`/open/${selectedPlaylist.id}`} onClick={(e) => navigate(e, `/open/${selectedPlaylist.id}`)}>Show all {selectedPlaylist.tracks.length} tracks</Button>
            </>
            } />
        }
    </>
    )
}