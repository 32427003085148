import { ConnectionContext } from "@/components/ConnectionContext";
import RecentPlaylists from "@/components/Homepage/RecentPlaylists";
import MainLayout from "@/layouts/MainLayout";
import { BeachAccess, Check, DirectionsRun, LibraryMusic, Storage, Warehouse } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Alert, Avatar, Box, Button, Container, Divider, Grid, ListItemContent, Sheet, Tooltip, Typography, accordionClasses, accordionDetailsClasses, accordionSummaryClasses } from "@mui/joy";
import { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useContext, useState } from "react";

const Page: NextPage = () => {

    const [selectedAccordion, setSelectedAccordion] = useState<string | null>('chatgpt');
    const { plex, roon } = useContext(ConnectionContext);
    const chatGPTURL = plex || roon
    return (<>
        <Head>
            <title>AI Guest DJ | Designed for Music Lovers</title>
        </Head>
        <MainLayout>
            <Sheet sx={{ p: 1, pt: 7, pb: 15 }}>
                <div style={{ position: 'absolute', bottom: -10, left: 0, width: '100%', transform: "scaleY(.6)", transformOrigin: "bottom" }} dangerouslySetInnerHTML={{
                    __html: `<svg class="waves waves--inverted" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax parallax--inverted">
                        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.1" />
                        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.1)" />
                        <use xlink:href="#gentle-wave" x="48" y="7" fill="var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))" />
                    </g>
                </svg>`}}></div>
                <Box position={'relative'} textAlign={"center"}>
                    <Box display={"inline-block"} padding={'5px'} boxSizing={"border-box"} bgcolor={"var(--joy-palette-neutral-700)"} component={"span"} overflow={"hidden"} borderRadius={"50%"} width={120} height={120}>
                        <Box overflow={"hidden"} borderRadius={"50%"} width={110} height={110}>
                            <Image src={"/img/logo.png"} alt={"AI Guest DJ"} width={110} height={110} />
                        </Box>
                    </Box>
                    <Typography level={"h1"}>AI Guest DJ</Typography>
                    <Typography mb={2} level={"body-sm"}>Designed for Roon and Plex!</Typography>
                    <Box display={'flex'} justifyContent={'center'} gap={1}>
                        {!chatGPTURL && <>
                            <Tooltip title="You haven't connected with Roon or Plex. It's better to start with that first">
                                <Button component={"a"} color="primary" href="https://chat.openai.com/g/g-IKyRDWbBe-ai-guest-dj" target="_blank" startDecorator={<Image src={"/img/icon-openai.svg"} alt="Open AI logo" width={20} height={20} />}>Open in ChatGPT</Button>
                            </Tooltip>
                        </>}
                        {chatGPTURL && <>
                            <Button component={"a"} color="primary" href="https://chat.openai.com/g/g-IKyRDWbBe-ai-guest-dj" target="_blank" startDecorator={<Image src={"/img/icon-openai.svg"} alt="Open AI logo" width={20} height={20} />}>Open in ChatGPT</Button>
                        </>}
                    </Box>
                </Box>
            </Sheet>
            <Sheet sx={{ minHeight: 1000, position: "relative" }} variant="soft" color="primary">
                <div style={{ position: 'absolute', bottom: -10, width: '100%', transform: "scaleY(.6)", transformOrigin: "bottom" }} dangerouslySetInnerHTML={{
                    __html: `<svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax">
                        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.1" />
                        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.1)" />
                        <use xlink:href="#gentle-wave" x="48" y="7" fill="var(--joy-palette-neutral-900, #0B0D0E)" />
                    </g>
                </svg>`}}></div>
                <Container maxWidth={"lg"} sx={{ p: 3, pt: 10, pb: 20 }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Box sx={{ margin: "0 auto", maxWidth: 500 }}>
                                <AccordionGroup
                                    disableDivider
                                    variant="plain"
                                    size="sm"
                                    transition="0.2s"
                                    sx={{
                                        [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                                        {
                                            padding: 2,
                                        },
                                        [`& .${accordionClasses.root}`]: {
                                            border: "1px solid rgba(255,255,255,0)",
                                            borderRadius: 4,
                                            marginBottom: 1,
                                        },
                                        [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
                                            border: "1px solid rgba(255,255,255,0.2)",
                                        },
                                        [`& .${accordionSummaryClasses.button}`]: {
                                            paddingBlock: '1rem',
                                        },
                                        [`& .${accordionClasses.root} .${accordionSummaryClasses.button}:hover`]: {
                                            background: 'rgba(255,255,255,0.1)'
                                        },
                                        [`& .${accordionSummaryClasses.expanded}.${accordionSummaryClasses.button}:hover`]: {
                                            background: 'rgba(255,255,255,0.1)'
                                        },
                                        [`& .${accordionSummaryClasses.expanded}.${accordionSummaryClasses.button}`]: {
                                            background: 'rgba(255,255,255,0.1)'
                                        },
                                    }}
                                >
                                    <Accordion
                                        expanded={selectedAccordion === 'chatgpt'}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? 'chatgpt' : null);
                                        }}>
                                        <AccordionSummary>

                                            <Avatar variant="solid" color="success">
                                                <Image src={"/img/icon-openai.svg"} alt="Open AI logo" width={20} height={20} />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">Powerd by ChatGPT</Typography>
                                                <Typography level="body-sm">
                                                    Tell what you like to hear today
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md">Turn your thoughts into music. For example:</Typography>
                                            <Box pt={2}>
                                                <Typography level="body-sm" mb={1} startDecorator={<DirectionsRun />}>An upbeat playlist of 30 minutes for my morning run</Typography>
                                                <Typography level="body-sm" mb={1} startDecorator={<BeachAccess />}>Great songs for a day at the beach</Typography>
                                                <Typography level="body-sm" mb={1} startDecorator={<LibraryMusic />}>Songs that match Cruel Summer by Taylor Swift</Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={selectedAccordion === 'local-files'}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? 'local-files' : null);
                                        }}>
                                        <AccordionSummary>
                                            <Avatar variant="solid" color="success">
                                                <Warehouse />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">For all the music hoarders</Typography>
                                                <Typography level="body-sm">
                                                    Works really well with local libraries
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md">The playlists will show any music that you might be missing. Of course this only works if you don&apos;t have a Tidal or Qobuz account connect to your music setup.</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={selectedAccordion === 'roon-plex-hass'}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? 'roon-plex-hass' : null);
                                        }}>
                                        <AccordionSummary>
                                            <Avatar variant="solid" color="success">
                                                <Image src={"/img/icon-roon-white.png"} alt="Roon logo" width={20} height={20} />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">Roon and Plex</Typography>
                                                <Typography level="body-sm">
                                                    Tailormade features for each platform
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md" mb={1} startDecorator={<Image src="/img/icon-plex-white.png" alt="Plex logo" width={18} height={18} />}>For Plex users</Typography>
                                            <Typography level="body-sm">You can save all your AI playlists in your library, and if the playlist already exists you can synchronise the changes.</Typography>
                                            <Typography mt={3} level="body-md" mb={1} startDecorator={<Image src="/img/icon-roon-white.png" alt="Roon logo" width={18} height={18} />}>For Roon users</Typography>
                                            <Typography level="body-sm">Roon doesn&apos;t really do well with playlists. So instead of creating playlists you can simply select the zone and start playing.</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={selectedAccordion === "openai"}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? "openai" : null);
                                        }}>
                                        <AccordionSummary>
                                            <Avatar variant="solid" color="success">
                                                <Image src={"/img/icon-openai.svg"} alt="Open AI Logo" width={20} height={20} />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">Connect to the API</Typography>
                                                <Typography level="body-sm">
                                                    Using your own Open AI API Key
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md">Instead of using ChatGPT Plus you can also use AI Guest DJ with your own API key.</Typography>
                                            <Typography level="body-sm" mt={1} mb={.5} startDecorator={<Check sx={{ fontSize: "1.1em" }} />}>Faster response time</Typography>
                                            <Typography level="body-sm" mb={.5} startDecorator={<Check sx={{ fontSize: "1.1em" }} />}>Lower costs, higher limits</Typography>
                                            <Typography level="body-sm" mb={.5} startDecorator={<Check sx={{ fontSize: "1.1em" }} />}>Secure. We don&apos;t store your keys</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={selectedAccordion === "manage-playlists"}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? "manage-playlists" : null);
                                        }}>
                                        <AccordionSummary>
                                            <Avatar variant="solid" color="success">
                                                <Image src={"/img/icon-musiclist-white.png"} alt="Music List icon" width={20} height={20} />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">Manage your AI playlists</Typography>
                                                <Typography level="body-sm">
                                                    Store and manage your playlists
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md">All the playlist you have created will be added to your account. Allowing you to finetune it to your needs.</Typography>
                                            <Typography level="body-sm" mt={2} mb={1} startDecorator={<Check sx={{ fontSize: "1.1em" }} />}>Select different versions of the song</Typography>
                                            <Typography level="body-sm" mb={1} startDecorator={<Check sx={{ fontSize: "1.1em" }} />}>Change the name or genre <sub>*</sub></Typography>
                                            <Typography level="body-sm" mb={1} startDecorator={<Check sx={{ fontSize: "1.1em" }} />}>Get new song suggestions based on the existing playlist <sub>*</sub></Typography>
                                            <Alert variant='outlined' color="neutral" sx={{ mt: 2, bgcolor: "transparent", fontWeight: 200 }} size="sm">* These features are still in developmet</Alert>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={selectedAccordion === "community"}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? "community" : null);
                                        }}>
                                        <AccordionSummary>
                                            <Avatar variant="solid" color="success">
                                                <Image src={"/img/icon-community-white.png"} alt="Community icon" width={20} height={20} />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">Discover new music</Typography>
                                                <Typography level="body-sm">
                                                    Created by other music lovers
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md">Browse and favorite any playlist created by other users and add those to your library</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={selectedAccordion === "servers"}
                                        onChange={(event, expanded) => {
                                            setSelectedAccordion(expanded ? "servers" : null);
                                        }}>
                                        <AccordionSummary>
                                            <Avatar variant="solid" color="success">
                                                <Storage />
                                            </Avatar>
                                            <ListItemContent>
                                                <Typography level="title-md">Easy setup</Typography>
                                                <Typography level="body-sm">
                                                    Run Plex and Open AI on our servers
                                                </Typography>
                                            </ListItemContent>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography level="body-md">The current setup is dependent on running docker containers locally. This makes it extra secure, but it isn&apos;t a plug and play setup. </Typography>
                                            <Typography mt={2} level="body-md">For Roon users we support the Roon Extension Manager, you can find AI Guest DJ in the category &quot;Playback&quot;</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionGroup>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box sx={{ display: { xs: "block", md: "none" } }}>
                                <Divider sx={{ mb: 6, mt: 3 }} />
                            </Box>
                            <Box sx={{ margin: "0 auto", maxWidth: 500 }}>
                                <RecentPlaylists />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Sheet>
            {/* <Sheet sx={{ minHeight: "50vh", position: "relative" }} className="verticalCenter">
                <JoinTheBeta />
            </Sheet> */}
        </MainLayout >


    </>
    )
}

export default Page;
