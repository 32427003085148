import { GetHomepagePlaylistResponse } from "@/pages/api/playlists";
import { Box, Card, Stack, Typography } from "@mui/joy";
import { ReactNode, useContext } from "react";
import { ConnectionContext } from "../ConnectionContext";
import Track from "./Track";

export type PlaylistCardProps = {
    title?: string
    playlist: GetHomepagePlaylistResponse,
    limit?: number,
    footer: ReactNode
}

export default function PlaylistCard(props: PlaylistCardProps) {
    const { limit = 5, title, playlist, footer } = props;
    const { roon, plex } = useContext(ConnectionContext);
    return (<Card variant="soft" sx={{ border: "1px solid rgba(255,255,255,0.4)" }} color="primary" invertedColors>
        <Box textAlign={"center"}>
            <Typography level="h2">{title || playlist.name}</Typography>
            <Typography level="body-sm" fontStyle={"italic"}>{playlist.prompt}</Typography>
        </Box>
        <Stack>
            {playlist.tracks.slice(0, limit).map(track => {
                return <Track
                    key={track.id}
                    loading={false}
                    trackName={track.name}
                    artistName={track.artist}
                    thumb={track.spotify_thumb}
                    reason={track.reason}
                />
            })}
        </Stack>
        <Box mt={1} display={"flex"} justifyContent={"center"}>
            {footer}
        </Box>
    </Card>)
}